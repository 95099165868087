(() => {
  window.armor = window.armor || {};

  function handleMainNavScroll() {
    if (window.pageYOffset > 26) {
      $('body').addClass('fixed-header');
    }
    else {
      $('body').removeClass('fixed-header');
    }
  }

  function initScrollListener() {
    window.addEventListener('scroll', () => {
      handleMainNavScroll();
    });
    handleMainNavScroll();
  }

  function addMediaQueryListener(query, handler) {
    const mql = window.matchMedia(query);
    mql.addListener(handler);
    handler(mql);
    return mql;
  }

  function initAlertBar() {
    const alertBar = document.querySelector('div.alert');
    const alertBarClose = document.querySelector('div.alert button.close');
    const alertObject = sessionStorage.getItem('alert');
    const pageMain = document.querySelector('section.masthead');
    const pageMainHome = document.querySelector('main.home');

    if (alertBar) {
      pageMain.classList.add('alert-show');
      pageMainHome.classList.add('alert-active');
    }

    // set storage on click
    alertBarClose.addEventListener('click', function() {
      pageMain.classList.remove('alert-show');
      sessionStorage.setItem('alert', 'dismiss');
    });

    // check storage for item
    if (typeof alertObject !== 'undefined' && alertObject !== null) {
      alertBar.remove();
      pageMain.classList.remove('alert-show');
      pageMainHome.classList.remove('alert-active');
    }
  }

  function initNewMenu() {
 // Toggle to show and hide navbar menu
 const navbarMenu = document.getElementById("menu");
 const burgerMenu = document.getElementById("burger");

 burgerMenu.addEventListener("click", () => {
   navbarMenu.classList.toggle("is-active");
   burgerMenu.classList.toggle("is-active");
 });

 // Toggle to show and hide dropdown menu
 const dropdown = document.querySelectorAll(".dropdown");


 dropdown.forEach((item) => {
   const dropdownToggle = item.querySelector(".dropdown-toggle");

   dropdownToggle.addEventListener("click", () => {
     const dropdownShow = document.querySelector(".dropdown-show");
     toggleDropdownItem(item);

     // Remove 'dropdown-show' class from other dropdown
     if (dropdownShow && dropdownShow != item) {
       toggleDropdownItem(dropdownShow);
     }
   });
 });

 // Function to display the dropdown menu
 const toggleDropdownItem = (item) => {
   const dropdownContent = item.querySelector(".dropdown-content");
   const backBtns = document.querySelectorAll(".btn-back");

   // Remove other dropdown that have 'dropdown-show' class
   if (item.classList.contains("dropdown-show")) {
     dropdownContent.removeAttribute("style");
     item.classList.remove("dropdown-show");
   } else {
     // Added max-height on active 'dropdown-show' class
     dropdownContent.style.height = dropdownContent.scrollHeight + "px";
     item.classList.add("dropdown-show");
     dropdownContent.classList.remove("back");
   }

   backBtns.forEach((item) => {
    item.addEventListener("click", () => {
      dropdownContent.classList.add("back");
      dropdown.forEach((item) => {
        item.classList.remove("dropdown-show");
      });
    });
  });
 };

 // Fixed dropdown menu on window resizing
 window.addEventListener("resize", () => {
   if (window.innerWidth > 992) {
     document.querySelectorAll(".dropdown-content").forEach((item) => {
       item.removeAttribute("style");
     });
     dropdown.forEach((item) => {
       item.classList.remove("dropdown-show");
     });
   }
 });

 // Fixed navbar menu on window resizing
 window.addEventListener("resize", () => {
   if (window.innerWidth > 992) {
     if (navbarMenu.classList.contains("is-active")) {
       navbarMenu.classList.remove("is-active");
       burgerMenu.classList.remove("is-active");
       backBtn.classList.remove("is-active");
     }
   }
 });

  }
  /* when mobile menu opens, darken background */
  function initMenuOverlay() {
    const menuBtn = document.querySelector('#menu-button-open');
    const menuBtnClose = document.querySelector('#menu-button-close');
    const menuNav= document.querySelector('#navbarNavDropdown');
    const tint = document.querySelector('div.overlay');

    menuBtn.addEventListener('click', function() {
        tint.classList.add('open-overlay');
    });

    function closeMenuOverlay() {
      tint.classList.remove('open-overlay');
    }

    function closeMenu() {
      tint.classList.remove('open-overlay');
      menuNav.classList.remove('show');
      menuBtn.classList.add('collapsed');
      const tintShowing = menuBtn.getAttribute('aria-expanded');
      if (tintShowing) {
        menuBtn.setAttribute('aria-expanded','false');
      }
    }

    menuBtnClose.addEventListener('click', closeMenuOverlay);
    tint.addEventListener('click', closeMenu);
  }

  function appendScriptTag(url, id) {
    const s = document.createElement('script');
    s.id = id;
    s.defer = true;
    s.src = url;

    $('body').append(s);
  }

  function initGoogleTagManager() {
    const gtmId = 'GTM-KM48VBH5';
    appendScriptTag(`https://www.googletagmanager.com/gtm.js?id=${ gtmId }&l=dataLayer`, 'gtm');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event:'gtm.js'
    });

    $('body').append($(`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${ gtmId }" height="0" width="0" style="display:none;visibility:hidden;"></iframe></noscript>`));
  }

  function initPardot() {
    window.piAId = '1024251';
    window.piCId = '6748';
    window.piHostname = 'go.armor.com';
    appendScriptTag(`https://${ window.piHostname }/pd.js`, 'pd');
  }

  function initHotjar() {
    window.hj = window.hj || function() {
      (window.hj.q = window.hj.q || []).push(arguments);
    };

    window._hjSettings = {
      hjid: 3402833,
      hjsv: 6,
    };

    appendScriptTag(
      `https://static.hotjar.com/c/hotjar-${ window._hjSettings.hjid }.js?sv=${ window._hjSettings.hjsv }`,
      'hotjar');
  }

  function initApollo() {
    var n=Math.random().toString(36).substring(7),o=document.createElement("script");
    o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
    o.onload=function() {
      window.trackingFunctions.onLoad({appId:"668679328ddc030787aec4e8"})
    },
      document.head.appendChild(o)
  }

  function initZoomInfo() {
    window[(function(_Bfz,_Ob){var _gOgjh='';for(var _zUfjZL=0;_zUfjZL<_Bfz.length;_zUfjZL++){var _rRPx=_Bfz[_zUfjZL].charCodeAt();_rRPx-=_Ob;_rRPx+=61;_rRPx%=94;_Ob>8;_rRPx!=_zUfjZL;_gOgjh==_gOgjh;_rRPx+=33;_gOgjh+=String.fromCharCode(_rRPx)}return _gOgjh})(atob('LXojRUI9ODZHfDhM'), 49)] = '8cd92bc4f81720025260'; var zi = document.createElement('script'); (zi.type = 'text/javascript'), (zi.async = true), (zi.src = (function(_zxh,_0b){var _9BWsl='';for(var _XCbMb4=0;_XCbMb4<_zxh.length;_XCbMb4++){_0b>9;var _6mXE=_zxh[_XCbMb4].charCodeAt();_6mXE!=_XCbMb4;_6mXE-=_0b;_6mXE+=61;_6mXE%=94;_9BWsl==_9BWsl;_6mXE+=33;_9BWsl+=String.fromCharCode(_6mXE)}return _9BWsl})(atob('cyEhe35FOjp1fjkndDh+bn10eyF+OW56eDondDghbHI5dX4='), 11)), document.readyState === 'complete'?document.body.appendChild(zi): window.addEventListener('load', function(){ document.body.appendChild(zi) });
  }

  function initLinkedIn() {
    const linkedinID = '5660660';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(linkedinID);
    (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);

    $('body').append($(`<noscript><img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${ linkedinID }&fmt=gif" /></noscript>`));
  }

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const i in cookies) {
      const cookie = cookies[i];
      const parts = cookie.split('=');
      if (parts[0].trim() === name) {
        return parts[1];
      }
    }
    return null;
  }

  function putCookie(name, value, days = 365, domain = null) {
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    let cookie = `${ name }=${ value };samesite=strict`;

    if (location.protocol === 'https:') {
      cookie += ';secure';
    }

    cookie += `;expires=${ expires.toUTCString() }`;

    if (domain) {
      cookie += `;domain=${ domain }`;
    }
    else {
      cookie += ';path=/';
    }

    document.cookie = cookie;
  }

  function eraseCookie(name, domain = null) {
    putCookie(name, '', -1, domain);
  }

  function setCookiePreference(allow, days = 365) {
    putCookie('accept-cookies', allow ? '1' : '0', days);
  }

  function acceptCookies() {
    setCookiePreference(true);
    $('.cookie-notice').css('display', 'none');
  }

  function declineCookies() {
    // Delete local cookies
    const existingCookies = document.cookie.split(';');
    for (const i in existingCookies) {
      eraseCookie(existingCookies[i].split('=')[0]);
    }

    setCookiePreference(false);
    location.reload();
  }

  function hasDeclinedCookies() {
    const cookie = getCookie('accept-cookies');
    return (cookie && cookie === '0');
  }

  function initCookieNotice() {
    if (document.cookie.indexOf('accept-cookies=') === -1) {
      $('.cookie-notice').css('display', 'block');
    }
  }

  function normalizeSlideHeights() {
    $('.carousel').each(function() {
      const items = $('.carousel-item', this);

      // Reset the minimum height and padding &ndash; we'll recalculate it and set it below.
      items.css('min-height', 0).css('padding-top', 0);

      // Temporarily make all of the slides "active" so we can capture their heights accurately.
      items.addClass('active');

      let maxHeight = 0;
      const wrappedItems = items.map(function() {
        const height = $(this).outerHeight();
        if (height > maxHeight) {
          maxHeight = height;
        }
        return {
          item: $(this),
          height
        };
      }).get();

      // Remove the active class and reset the loop to the first slide.
      items.removeClass('active');
      items.first().addClass('active');

      wrappedItems.forEach((wrapper) => {
        wrapper.item.css('padding-top', `${ (maxHeight - wrapper.height) / 2 }px`)
          .css('min-height', `${ maxHeight }px`);
      });
    });
  }

  function initSlideHeightListener() {
    $(window).on('load resize orientationchange', normalizeSlideHeights);
  }

  function initTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  // Themes
  const themeListeners = [];
  function registerListener(delegate) {
    themeListeners.push(delegate);
  }

  function getThemePreference() {
    const pref = window.localStorage.getItem('preferences:theme');
    return (!pref || pref === 'null') ? 'light' : pref;
  }

  function setThemePreference(theme) {
    window.localStorage.setItem('preferences:theme', theme);
  }

  function setTheme(theme) {
    const body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-display-theme', theme);
    setThemePreference(theme);
    for (const listener of themeListeners) {
      listener(theme);
    }
  }

  function getTheme() {
    return document.getElementsByTagName('body')[0].getAttribute('data-display-theme');
  }

  window.armor.themes = {
    getTheme,
    setTheme,
    registerListener,
    getThemePreference,
    setThemePreference,
  };

  const FEATURE_OFFSET = 100;

  function scrollToSection(selector) {
    const targetSection = $(selector);
    window.scrollTo({
      top: targetSection.offset().top - FEATURE_OFFSET,
      behavior: 'smooth'
    });
  }

  document.addEventListener('DOMContentLoaded', () => {
    setTheme(getThemePreference());
    initScrollListener();
    initCookieNotice();
    initSlideHeightListener();
    initTooltips();
    // initMenuOverlay();
    initNewMenu();

    if (!hasDeclinedCookies()) {
      initGoogleTagManager();
      initPardot();
      initLinkedIn();
      initApollo();
      initZoomInfo();
    }
  });


  window.qs = window.qs || {};
  window.qs.cookies = {
    getCookie,
    putCookie,
    acceptCookies,
    declineCookies,
    hasDeclinedCookies,
  };

  window.qs.scrolling = {
    scrollToSection,
    FEATURE_OFFSET,
  };
})();
